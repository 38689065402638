<template>
  <v-card class="mt-6">
    <common-loading
      :title="loading.title"
      :message="loading.message"
      :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading"
      :show_confirm="loading.show_confirm"
      v-on:confirm="loading.dialog = false"
    >
    </common-loading>
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>
    <v-dialog v-model="dialog" width="40%">
      <v-card>
        <v-card-title class="text-center">
          <h4 class="text-typo font-weight-bolder">新增角色</h4>
        </v-card-title>
        <v-row class="mx-4 mt-4">
          <v-col cols="12">
            <label class="text-sm text-typo font-weight-bolder ms-1"
              >名稱(英文)</label
            >
            <v-text-field
              hide-details
              outlined
              v-model="role.name"
              :class="text_edit_class"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <label class="text-sm text-typo font-weight-bolder ms-1"
              >描述</label
            >
            <v-text-field
              hide-details
              outlined
              v-model="role.description"
              :class="text_edit_class"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-card-actions class="mx-4">
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            color="#5e72e4"
            @click="dialog = false"
            >取消</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            color="#5e72e4"
            @click="onConfirm"
            >確定</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-toolbar flat>
        <v-col cols="5" class="mx-0 px-0">
          <v-select
            :items="getRoles()"
            v-model="role_id"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              border-radius-md
              select-style
              mt-8
              mx-3
            "
            outlined
            single-line
            item-text="text"
            item-value="id"
            @change="onRoleChanged()"
          ></v-select
        ></v-col>

        <v-spacer></v-spacer>
        <v-col cols="auto" self-align="end">
          <v-btn
            v-if="false"
            :ripple="false"
            elevation="0"
            color="#fff"
            class="
              font-weight-bolder
              btn-default
              bg-gradient-primary
              py-5
              px-6
              mx-3
            "
            small
            @click="dialog = true"
          >
            <v-icon class="me-2" size="12">fa fa-search</v-icon>
            新增角色
          </v-btn>

          <v-btn
            :ripple="false"
            elevation="0"
            color="#fff"
            class="
              font-weight-bolder
              btn-default
              bg-gradient-default
              py-5
              px-6
              mx-3
            "
            small
            @click="update()"
          >
            <v-icon class="me-2" size="12">fa fa-search</v-icon>
            更新
          </v-btn>
        </v-col>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table class="table border-radius-xl" dense>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="auto">
                  <v-btn
                    :ripple="false"
                    elevation="0"
                    color="#fff"
                    class="
                      font-weight-bolder
                      btn-default
                      bg-gradient-default
                      py-5
                      px-6
                      mx-3
                      ms-auto
                    "
                    small
                    @click="filter_type = 0"
                  >
                    功能
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    elevation="0"
                    color="#fff"
                    class="
                      font-weight-bolder
                      btn-default btn-outline-secondary
                      py-5
                      px-6
                      mx-3
                      ms-auto
                    "
                    small
                    @click="filter_type = 1"
                  >
                    頁面
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:default>
            <thead>
              <tr>
                <td
                  class="
                    text-secondary
                    font-weight-bolder
                    text-md
                    border-bottom
                  "
                  width="30%"
                >
                  權限名稱
                </td>
                <td
                  class="
                    text-secondary
                    font-weight-bolder
                    text-md
                    border-bottom
                  "
                >
                  讀取
                </td>
                <td
                  class="
                    text-secondary
                    font-weight-bolder
                    text-md
                    border-bottom
                  "
                >
                  新增
                </td>

                <td
                  class="
                    text-secondary
                    font-weight-bolder
                    text-md
                    border-bottom
                  "
                >
                  修改
                </td>
                <td
                  class="
                    text-secondary
                    font-weight-bolder
                    text-md
                    border-bottom
                  "
                >
                  刪除
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in permissions_groups.filter(
                  (item) => item.type == filter_type
                )"
                :key="item.id"
              >
                <!-- group name -->
                <td
                  :class="{
                    'border-bottom': i != permissions_groups.length - 1,
                  }"
                >
                  <span class="font-weight-bold mb-0 text-md">{{
                    item.group
                  }}</span>
                </td>
                <!-- get read -->
                <td
                  :class="{
                    'border-bottom': i != permissions_groups.length - 1,
                  }"
                >
                  <v-checkbox
                    v-if="item.permissions.get"
                    v-model="item.permissions.get.checked"
                    class="mt-4"
                    dense
                  ></v-checkbox>
                </td>
                <!-- get create -->
                <td
                  :class="{
                    'border-bottom': i != permissions_groups.length - 1,
                  }"
                >
                  <v-checkbox
                    v-if="item.permissions.post"
                    v-model="item.permissions.post.checked"
                    class="mt-4"
                    dense
                  ></v-checkbox>
                </td>
                <!-- get modify -->
                <td
                  :class="{
                    'border-bottom': i != permissions_groups.length - 1,
                  }"
                >
                  <v-checkbox
                    v-if="item.permissions.put"
                    v-model="item.permissions.put.checked"
                    class="mt-4"
                    dense
                  ></v-checkbox>
                </td>
                <td
                  :class="{
                    'border-bottom': i != permissions_groups.length - 1,
                  }"
                >
                  <v-checkbox
                    v-if="item.permissions.delete"
                    v-model="item.permissions.delete.checked"
                    class="mt-4"
                    dense
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import RoleMixin from "@/components/CRM/RoleMixin.vue";
import { common_text_edit_class } from "@/definitions";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
export default {
  name: "RoleView",
  data() {
    return {
      groupOpened: false,
      role_id: null,
      all_permission_groups: [],
      permissions_groups: [],
      filter_type: 0,
      headers: [
        {
          text: "Name",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "description",
          sortable: false,
          value: "description",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "object",
          sortable: false,
          value: "object",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
      ],
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: false,
        show_confirm: true,
      },
      dialog: false,
      role: {
        name: null,
        description: null,
        mode: 0, // 0 create, 1 edit
      },
      text_edit_class: common_text_edit_class,
    };
  },
  mixins: [HttpCommonMixin, RoleMixin],
  components: {
    CommonLoading,
    CommonConfirm,
  },
  computed: {
    ...mapState(["roles"]),
  },
  beforeMount() {
    this.get_all_permissions();
  },
  mounted() {
    // TODO 可以refine  getRolesFromServer
    this.getRolesFromServer(this.setRoleResult, this.search_role);
  },
  methods: {
    ...mapGetters(["getToken", "getRoles"]),
    setRoleResult(data) {
      console.log("setRoleResult", data);
      this.parseRoleResult(data);
      this.search_role();
    },
    search_role() {
      const roles = this.getRoles();
      if (roles.length == 0) return;
      this.role_id = roles[0].id;

      console.log("search role for detail:", this.role_id);
      this.search();
    },

    get_all_permissions() {
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/rbac/permissions`;
      this.doHttpGet(url, null, this.setAllPermissions);
    },
    setAllPermissions(data) {
      let groups = [];
      data.forEach((item) => {
        if (item.group != null) {
          let result = groups.find((obj) => {
            return obj.group == item.group;
          });
          if (result == undefined) {
            groups.push({ group: item.group, items: [item], type: item.type });
          } else {
            result.items.push(item);
          }
        }
      });

      this.permissions_groups = groups;
      this.permissions_groups.forEach((group) => {
        group.permissions = { get: null, post: null, put: null, delete: null };
        group.items.forEach((per) => {
          // if action is "GET" , put into group.permissions
          per.checked = false;
          if (per.action == "GET") {
            group.permissions.get = per;
          } else if (per.action == "POST") {
            group.permissions.post = per;
          } else if (per.action == "PUT") {
            group.permissions.put = per;
          } else if (per.action == "DELETE") {
            group.permissions.delete = per;
          }
        });
      });

      // console.log("all permissions", this.permissions_groups);
    },
    search() {
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/rbac/role`;
      let params = {
        id: this.role_id,
      };
      this.doHttpGet(url, params, this.setSearchResult);
    },
    checkPermission(group, data, type) {
      if (group.permissions[type] != null) {
        // if find the permission as follow {object: '/api/v1/*', action: '*'}, then check it
        let isSuper = data.find((obj) => {
          return obj.action == "*" && obj.object == "/api/v1/*";
        });
        if (isSuper != undefined && isSuper != null) {
          group.permissions[type].checked = true;
          return;
        }
        let result = data.find((obj) => {
          return (
            obj.action == group.permissions[type].action &&
            obj.object == group.permissions[type].object
          );
        });
        group.permissions[type].checked =
          result !== undefined && result !== null;
      }
    },
    setSearchResult(data) {
      console.log("setSearchResult", data);
      let tmp = _.cloneDeep(this.permissions_groups);
      this.permissions_groups = [];

      tmp.forEach((group) => {
        this.checkPermission(group, data, "get");
        this.checkPermission(group, data, "post");
        this.checkPermission(group, data, "put");
        this.checkPermission(group, data, "delete");
      });

      this.permissions_groups = tmp;
    },
    update() {
      this.confirm = {
        dialog: true,
        title: "更新",
        message: "確定要更新嗎?",
        function: this.onUpdateConfirm,
      };
    },

    onUpdateConfirm() {
      this.confirm.dialog = false;
      this.loading = {
        dialog: true,
        title: "更新中",
        message: "更新中，請稍後",
        show_loading: true,
        show_confirm: false,
      };
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/rbac/role`;
      let objects = [];
      this.permissions_groups.forEach((group) => {
        console.log(group.permissions);
        // objects.push(item.id);
        if (
          group.permissions.get != null &&
          group.permissions.get.checked == true
        ) {
          objects.push(group.permissions.get.id);
        }
        if (
          group.permissions.post != null &&
          group.permissions.post.checked == true
        ) {
          objects.push(group.permissions.post.id);
        }
        if (
          group.permissions.put != null &&
          group.permissions.put.checked == true
        ) {
          objects.push(group.permissions.put.id);
        }
        if (
          group.permissions.delete != null &&
          group.permissions.delete.checked == true
        ) {
          objects.push(group.permissions.delete.id);
        }
      });

      const selectedRole = this.roles.find((obj) => {
        return obj.id == this.role_id;
      });
      const data = {
        name: selectedRole.value,
        permissions: objects,
      };
      console.log(data);
      this.doHttpPut(url, data, this.setUpdateSuccess, this.setUpdateFail);
    },
    setUpdateSuccess(data) {
      console.log(data);
      this.showResult("更新成功");
    },
    setUpdateFail(data) {
      console.log(data);
      this.showResult("更新失敗");
    },
    showResult(msg) {
      this.loading = {
        dialog: true,
        title: "資訊",
        message: msg,
        show_loading: false,
        show_confirm: true,
      };
    },
    onRoleChanged() {
      this.search();
    },
    onAddRoleConfirm() {
      this.loading = {
        dialog: true,
        title: "新增中",
        message: "新增中，請稍後",
        show_loading: true,
        show_confirm: false,
      };
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/rbac/role`;
      const data = {
        name: this.role.name,
        description: this.role.description,
      };
      this.doHttpPost(url, data, this.onAddDone, this.onAddFail);
    },
    onAddFail() {
      this.loading = {
        dialog: true,
        title: "新增失敗",
        message: "新增角色失敗",
        show_loading: false,
        show_confirm: true,
      };
    },
    onAddDone() {
      console.log("Clear the cache and get from server again");
      this.loading = {
        dialog: true,
        title: "新增成功",
        message: "新增角色成功",
        show_loading: false,
        show_confirm: true,
      };
      this.role = {
        name: null,
        description: null,
        mode: 0, // 0 create, 1 edit
      };
      this.getRolesFromServer(this.setRoleResult, this.search_role, true);
    },
    getItemByAction(items, action) {
      let result = items.find((obj) => {
        return obj.action == action;
      });
      if (result == undefined) {
        return false;
      } else {
        return true;
      }
    },
    getReadAction(item) {
      return this.getItemByAction(item, "GET");
    },
    getCreateAction(item) {
      return this.getItemByAction(item, "POST");
    },
    getModifyAction(item) {
      return this.getItemByAction(item, "PUT");
    },
    getDeleteAction(item) {
      return this.getItemByAction(item, "DELETE");
    },
    onConfirm() {
      if (this.role.mode == 0) {
        this.onAddRoleConfirm();
      } else {
        //
      }
      this.dialog = false;
    },
  },
};
</script>
